<template>
<section class="text-gray-400 bg-gray-900 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <div class="flex flex-col text-center w-full mb-20">
      <h2 class="text-xs text-yellow-400 tracking-widest font-medium title-font mb-1">Alpha</h2>
      <h1 class="sm:text-3xl text-2xl font-medium title-font text-white">Valor Wikipedia</h1>
    </div>
    <div class="flex flex-wrap -m-4">
      <div class="p-4 md:w-1/3">
        <div class="flex rounded-lg h-full bg-gray-800 bg-opacity-60 p-8 flex-col">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-yellow-500 text-white flex-shrink-0">
                <img src="https://vwiki.valorserver.com/api/item/picture/Oblivion" class="w-8 h-8 bg-gray-800 p-1">

            </div>
            <h2 class="text-white text-lg title-font font-medium">Items</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-gray-500">View all the items in Valor sorted by item tier. Each item can be clicked on to view stats and information about it.</p>
            <router-link to="/items" class="mt-3 text-yellow-400 inline-flex items-center">Visit
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </router-link>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/3">
        <div class="flex rounded-lg h-full bg-gray-800 bg-opacity-60 p-8 flex-col">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-yellow-500 text-white flex-shrink-0">
                <img src="https://vwiki.valorserver.com/api/item/picture/Onrane Cache" class="w-8 h-8 bg-gray-800 p-1">

            </div>
            <h2 class="text-white text-lg title-font font-medium">Mechanics</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-gray-500">Learn about the specialized Valor mechanics here. Such as our custom classes, and different currencies.</p>
            <router-link to="/mechanics" class="mt-3 text-yellow-400 inline-flex items-center">Info
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </router-link>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/3">
        <div class="flex rounded-lg h-full bg-gray-800 bg-opacity-60 p-8 flex-col">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-yellow-500 text-white flex-shrink-0">
                <img src="https://vwiki.valorserver.com/api/boss/picture/Malgor, the Eternal Fiend" class="w-8 h-8 bg-gray-800 p-1">
            </div>
            <h2 class="text-white text-lg title-font font-medium">Bosses</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-gray-500">View all of the Quest Bosses. See boss information such as projectile stats and max health.</p>
            <router-link to="/bosses" class="mt-3 text-yellow-400 inline-flex items-center">Boss
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>

<div class="p-4 md:w-1/3">
        <div class="flex rounded-lg h-full bg-gray-800 bg-opacity-60 p-8 flex-col">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-yellow-500 text-white flex-shrink-0">
                <img src="https://vwiki.valorserver.com/api/item/picture/Faith of the Angel" class="w-8 h-8 bg-gray-800 p-1">

            </div>
            <h2 class="text-white text-lg title-font font-medium">Damage Calculator</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-gray-500">This is a community maintained spreadsheet that allows you to experiment with different builds.</p>
            <a href="https://docs.google.com/spreadsheets/d/1tmiPVNJgPfR2AshHDQWpc-19dcEOhW7W-l_QpPyTFfA/edit?usp=sharing" class="mt-3 text-yellow-400 inline-flex items-center">Access
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/3">
        <div class="flex rounded-lg h-full bg-gray-800 bg-opacity-60 p-8 flex-col">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-yellow-500 text-white flex-shrink-0">
                <img src="https://vwiki.valorserver.com/api/item/picture/10000 Gold" class="w-8 h-8 bg-gray-800 p-1">
            </div>

            
            <h2 class="text-white text-lg title-font font-medium">RWT (Real World Trading)</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-gray-500">Valor allows all verified players to RWT with other Valor players. Check out how to verify, the rules, and banned users here.</p>
            <a href="https://valors.notion.site/RWT-2a93add09a5b4263925ce68cf3dc6a73" class="mt-3 text-yellow-400 inline-flex items-center">Info
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="p-4 md:w-1/3">
        <div class="flex rounded-lg h-full bg-gray-800 bg-opacity-60 p-8 flex-col">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-yellow-500 text-white flex-shrink-0">
                <img src="https://vwiki.valorserver.com/api/item/picture/Sor Crystal" class="w-8 h-8 bg-gray-800 p-1">

            </div>
            <h2 class="text-white text-lg title-font font-medium">Game Recording & PlayBack</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-gray-500">Want to record your gameplay, or having issues with performance? Click here.</p>
            <a href="https://blog.valorserver.com/game-recording/" class="mt-3 text-yellow-400 inline-flex items-center">Visit
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>

      
      <div class="p-4 md:w-1/3">
        <div class="flex rounded-lg h-full bg-gray-800 bg-opacity-60 p-8 flex-col">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-yellow-500 text-white flex-shrink-0">
                <img src="https://vwiki.valorserver.com/api/item/picture/Lost Scripture" class="w-8 h-8 bg-gray-800 p-1">

            </div>
            <h2 class="text-white text-lg title-font font-medium">Play On Mac?</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-gray-500">Struggling to get Valor running on Mac? We have a step by step tutorial.</p>
            <a href="https://blog.valorserver.com/valor-on-macos/" class="mt-3 text-yellow-400 inline-flex items-center">Visit
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      


  </div>
</section>
</template>

<script>
export default {
  name: "Home",
};
</script>
<style scoped>

.home {
  cursor:url('https://vwiki.valorserver.com/api/item/picture/Insignia%20of%20Valor'), auto;
}


</style>