import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/404.vue";
import Items from "../views/items/Items.vue";
import Bosses from "../views/bosses/Bosses.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/item/:name",
    name: "Item",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/items/Item.vue"),
  },
  {
    path: "/items",
    component: Items,
  },
  {
    path: "/bosses",
    component: Bosses,
  },
  {
    path: "/boss/:name",
    name: "Boss",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/bosses/Boss.vue"),
  },
  {
    path: "/commands",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Commands.vue"),
  },
  {
    path: "/mechanics",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Mechanics.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
