<template>
 <div class="about justify-center flex bg-gray-900 items-center h-screen">
    <div class="text-4xl text-white">Nothing found here?!</div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
<style scoped>

.about {
  cursor:url('https://vwiki.valorserver.com/api/item/picture/anatis%20staff'), auto;
}

</style>