<template>
  <div class="bg-gray-900 text-center py-2 lg:px-2">
    <div class="p-2 bg-indigo-900 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
      <span class="flex rounded-full bg-indigo-700 uppercase px-2 py-1 text-xs font-bold mr-3">Info</span>
      <a href="https://wiki.valorserver.com" class="font-semibold mr-2 text-left flex-auto">The wiki has been updated. This is now used for viewing items. Visit the wiki here.</a>
    </div>
  </div>
  <header class="text-gray-400 bg-gray-900 body-font">
  <div class="container mx-auto flex flex-wrap p-1 flex-col md:flex-row items-center">
    <nav class="flex lg:w-2/5 flex-wrap items-center text-base md:ml-auto">
      <router-link class="nav-cursor mr-5 hover:text-white" to="/items">Items</router-link>
      <router-link class="nav-cursor-bosses mr-5 hover:text-white" to="/bosses">Bosses</router-link>
      <router-link class="text-green-300 nav-cursor mr-5 hover:text-white" to="/mechanics">New Players</router-link>
      <router-link class="nav-cursor mr-5 hover:text-white" to="/commands">Commands</router-link>
      <a href="https://blog.valorserver.com" class="hover:text-white mr-5">Blog</a>
      <a href="https://valorserver.com" class="hover:text-white">Main</a>

    </nav>
    <a class="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-white lg:items-center lg:justify-center mb-4 md:mb-0">
      <img src="https://imgur.com/HKW5Kyw.png" class="text-white p-2 shadow-lg w-12 h-12 rounded-full">
      <router-link to="/" class="ml-3 text-xl xl:block lg:hidden text-yellow-400 font-blocky">Valor Wiki</router-link>
    </a>
    <div class="lg:w-2/5 inline-flex lg:justify-end ml-5 lg:ml-0">
      <a href="https://discord.gg/valormc" class="inline-flex items-center bg-yellow-700 border-0 py-1 px-3 focus:outline-none hover:bg-yellow-500 text-gray-900 rounded text-base mt-4 md:mt-0">Discord
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </a>
    </div>
  </div>
</header>
  <router-view />

  <div class="container w-full mx-auto bg-gray-900">
    <div class="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
      <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <img src="https://imgur.com/HKW5Kyw.png" class="text-white p-2 shadow-lg w-12 h-12 rounded-full">
      </a>
      <p class="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2022 Valor —
        <a href="https://discord.gg/BB5rFNpvcP" rel="noopener noreferrer" class="text-gray-600 ml-1" target="_blank">@Arcanuo</a>
      </p>
      <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
        <a href="https://valorserver.com/ranks" class="ml-3 text-yellow-500">
          <p><img src="https://vwiki.valorserver.com/api/item/picture/Gold%20Valor%20Boost" class="text-white w-6 w-6 inline-block p-1">Like Valor? <span class="text-gray-500">Support Us</span>.</p>
        </a>
      </span>
      </div>
    </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Press+Start+2P&family=MedievalSharp&display=swap');

h1, h2, h3, .header {
  font-family: 'Montserrat', sans-serif;
}
.font-blocky {
    font-family: 'Bangers', cursive;
}
#app, p {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
.nav-cursor:hover {
    cursor:url('https://vwiki.valorserver.com/api/item/picture/Insignia%20of%20Valor?size=20'), auto;
}

.nav-cursor-bosses:hover {
    cursor:url('https://vwiki.valorserver.com/api/boss/picture/Malgor, the Eternal Fiend'), auto;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
