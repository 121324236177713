<template>
<div v-if="isError">
  <div class="about justify-center flex bg-gray-900 items-center h-screen">
    <div class="text-4xl text-white">Nothing to Display</div>
  </div>
</div>
<div v-else  class="bg-gray-900">


 
 <div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
    <div class="text-center mb-10">
      <h1 class="mb-2 text-3xl font-black tracking-tighter text-red-400 lg:text-5xl md:text-4xl">Quest Bosses</h1>
      <div class="flex mt-6 justify-center">
        <div class="w-16 h-1 rounded-full bg-red-400 inline-flex"></div>
      </div>
    </div>
 <div class="inline-block m-3 p-2 shadow-lg rounded-lg bg-gradient-to-r from-red-400 via-red-500 to-red-700" v-for="boss in bosses" v-bind:key="boss.$.type">
      <router-link  class="has-tooltip" :to="{ name: 'Boss', params: { name: boss.$.id }}">
        <img :src="'https://vwiki.valorserver.com/api/boss/picture/' + boss.$.id" class="object-cover w-8 h-8 shadow-lg">
        <span class='tooltip rounded shadow-lg inline-block p-2 m-1 bg-red-800 text-red-500'>{{ boss.$.id }}</span>
      </router-link>
    </div>
 </div>     
 </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Bosses",
  data() {
    return {
      bosses: [],
      error: "",
      isError: false,
    };
  },

  methods: {    
    hexToDec(hexString){
        return parseInt(hexString, 16);
    },
    async getData() {
      try {
        const response = await axios.get("https://vwiki.valorserver.com/api/bosses/quests");
        //this.consumables = consumableResponse.data;
        this.bosses = response.data;
        console.log(response);
      
      } catch (error) {
        console.log(error);
        this.isError = true;
        this.error = "Item not found!";
        this.$route.params.name = "";
        this.items = "";

      }
    },
  },

  created() {
    this.getData();
  },
};
</script>
