<template>
<div v-if="isError">
  <div class="about justify-center flex bg-gray-900 items-center h-screen">
    <div class="text-4xl text-white">Nothing to Display</div>
  </div>
</div>
<div v-else  class="bg-gray-900">

 <div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
    <div class="text-center mb-10">
      <h1 class="mb-2 text-3xl font-black tracking-tighter text-yellow-400 lg:text-5xl md:text-4xl">Legendaries</h1>
       <select @change="onChange($event)" v-model="shardSelect" class="font-bold rounded border-2 border-yellow-700 text-yellow-600  pl-5 pr-10 bg-gray-800 hover:border-yellow-400 focus:outline-none appearance-none">
           <option  value="">Sort by Shard</option>
            <option>Stone Shard</option>
            <option>Fury Shard</option>
            <option>Cosmic Shard</option>
            <option>Grand Ancients Shard</option>
            <option>Shard of the Ancient Assault</option>
            <option>Challenge Shard</option>
            <option>Zol Shard</option>

        </select>
      <div class="flex mt-6 justify-center">
        <div class="w-16 h-1 rounded-full bg-yellow-400 inline-flex"></div>
      </div>
    </div>
 <div class="inline-block m-3 p-2 shadow-lg rounded-lg bg-gradient-to-r from-yellow-700 via-yellow-500 to-yellow-600" v-for="item in items" v-bind:key="item.$.type">
      <router-link  class="has-tooltip" :to="{ name: 'Item', params: { name: item.$.id }}">
        <img :src="'https://vwiki.valorserver.com/api/item/picture/' + item.$.id" @error="setAltImg" class="object-cover w-6 h-6 shadow-lg">
        <span class='tooltip rounded shadow-lg bg-yellow-600 inline-block text-gray-900 p-1 rounded-md'>{{ item.$.id }}  <span v-if="item.Shard != undefined">- {{ item.Shard }} </span></span>
      </router-link>
    </div>
 </div>     

 
 <div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
    <div class="text-center mb-10">
      <h1 class="mb-2 text-3xl font-black tracking-tighter text-yellow-800 lg:text-5xl md:text-4xl">Relics</h1>
      <div class="flex mt-6 justify-center">
        <div class="w-16 h-1 rounded-full bg-yellow-800 inline-flex"></div>
      </div>
    </div>
 <div class="inline-block m-3 p-2 shadow-lg rounded-lg bg-gradient-to-r from-yellow-800 via-yellow-900 to-red-800" v-for="item in relics" v-bind:key="item.$.type">
      <router-link  class="has-tooltip" :to="{ name: 'Item', params: { name: item.$.id }}">
        <img :src="'https://vwiki.valorserver.com/api/item/picture/' + item.$.id" class="object-cover w-6 h-6 shadow-lg">
        <span class='tooltip rounded shadow-lg bg-yellow-900 inline-block text-red-400 p-1 rounded-md'>{{ item.$.id }}</span>
      </router-link>
    </div>
 </div>      

 
 <div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
    <div class="text-center mb-10">
      <h1 class="mb-2 text-3xl font-black tracking-tighter text-red-400 lg:text-5xl md:text-4xl">Fabled Items</h1>
      <div class="flex mt-6 justify-center">
        <div class="w-16 h-1 rounded-full bg-red-400 inline-flex"></div>
      </div>
    </div>
 <div class="inline-block m-3 p-2 shadow-lg rounded-lg bg-gradient-to-r from-red-500 via-red-600 to-red-800" v-for="item in fableds" v-bind:key="item.$.type">
      <router-link  class="has-tooltip" :to="{ name: 'Item', params: { name: item.$.id }}">
        <img :src="'https://vwiki.valorserver.com/api/item/picture/' + item.$.id" class="object-cover w-6 h-6 shadow-lg">
        <span class='tooltip rounded shadow-lg bg-red-500 inline-block text-gray-900 p-1 rounded-md'>{{ item.$.id }}</span>
      </router-link>
    </div>
 </div>     
 
 <div class="container px-5 pt-5 mx-auto lg:px-32 items-center">
    <div class="text-center mb-10">
      <h1 class="mb-2 text-3xl font-black tracking-tighter text-purple-400 lg:text-5xl md:text-4xl">Upgradeable UT's</h1>
      <div class="flex mt-6 justify-center">
        <div class="w-16 h-1 rounded-full bg-purple-400 inline-flex"></div>
      </div>
    </div>
 <div class="inline-block m-3 p-2 shadow-lg rounded-lg bg-gradient-to-r from-purple-500 via-purple-600 to-purple-800" v-for="item in upgrades" v-bind:key="item.$.type">
      <router-link class="has-tooltip" :to="{ name: 'Item', params: { name: item.$.id }}">
        <img :src="'https://vwiki.valorserver.com/api/item/picture/' + item.$.id" class="object-cover w-6 h-6 shadow-lg">
        <span class='tooltip rounded shadow-lg bg-purple-500 inline-block text-gray-900 p-1 rounded-md'>{{ item.$.id }}</span>
      </router-link>
    </div>
 </div>     
 </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Item",
  data() {
    return {
      shardSelect: "",
      items: [],
      relics: [],
      fableds: [],
      upgrades: [],
      error: "",
      isError: false,
    };
  },

  methods: {    
    setAltImg(event) { 
    event.target.src = "https://vwiki.valorserver.com/api/item/picture/Useless Katana" 
    }, 
    hexToDec(hexString){
        return parseInt(hexString, 16);
    },
    async onChange(event) {
        try {
        const sortedLegendaries = await axios.get("https://vwiki.valorserver.com/api/items/legendaries?shard=" + event.target.value);
        this.items = sortedLegendaries.data;
        } catch (error) {
          this.isError = "Error";
          this.$route.params.name = "";
           this.items = "";
        }
    },

    async getData() {
      try {
        const response = await axios.get("https://vwiki.valorserver.com/api/items/legendaries");
        const relicResponse = await axios.get("https://vwiki.valorserver.com/api/items/relics");
        const fabledResponse = await axios.get("https://vwiki.valorserver.com/api/items/fableds");
        const upgradesResponse = await axios.get("https://vwiki.valorserver.com/api/items/upgrades");

        //this.consumables = consumableResponse.data;
        this.items = response.data;
        this.relics = relicResponse.data;
        this.fableds = fabledResponse.data;
        this.upgrades = upgradesResponse.data;
      
      } catch (error) {
        this.isError = true;
        this.error = "Item not found!";
        this.$route.params.name = "";
        this.items = "";

      }
    },
  },

  created() {
    this.getData();
  },
};
</script>
