import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
})],
  state: {
    shardInput: ""
  },
  mutations: {
   setShardSearch(state, value) {
    state.shardInput = value;
   } 
  },
  getters: {
    getShardSearch: state => {
      return state.shardInput
    }
  },
  actions: {
  },
  modules: {
  }
})
